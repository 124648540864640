@import "./Mixins/rem-utility";
@import "./Modules/webfonts";

:root {
    --app-backgorund-color: #f0f2f5;
    --app-light-color: #ffffff;
    --app-dark-blue-color: #24435b;
    --app-mid-blue-color: #407295;
    --app-light-blue-color: #009ee0;
}

body {
    font-size: rem(16px);
    background-color: var(--app-light-color);
    font-family: Poppins, sans-serif;
}

@import "./Modules/layout";
@import "./Modules/pokertable";
@import "./Modules/card";
@import "./Modules/settings";
