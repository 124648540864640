#root {
    display: flex;
    min-height: 100%;
}

.ant-layout-header {
    position: sticky;
    top: 0;
    background-color: var(--app-light-color);
    box-shadow: 0 0 0 0 rgba(#000, .0);
    transition: box-shadow .3s ease-out;
    z-index: 1000;

    &.ant-layout-header--scroll {
        box-shadow: 0 11px 6px -8px rgba(#000, .18);
    }

    h4 {
        font-weight: 400;
    }
}

.ant-layout {
    background-color: rgba(#009ee0, .1);
}

.ant-layout-content {
    display: flex;
    flex-direction: row;
    padding: 20px;
}

.ant-layout-footer {
    position: sticky;
    bottom: 0;
    background-color: var(--app-backgorund-color);

    a {
        color: var(--app-mid-blue-color);
    }
}

.ant-btn.ant-btn-primary:not([disabled]) {
    border-color: var(--app-light-blue-color);
    background-color: var(--app-light-blue-color);
    background: var(--app-light-blue-color);

    &:focus:not([disabled]),
    &:hover:not([disabled]) {
        border-color: var(--app-dark-blue-color);
        background-color: var(--app-dark-blue-color);
        background: var(--app-dark-blue-color);
    }
}

.ant-switch-checked {
    background-color: var(--app-light-blue-color);
}
