@font-face {
    font-display: swap;
    font-family: Poppins;
    font-style: normal;
    font-weight: 100;
    src: url('../../Assets/Fonts/poppins-v20-latin-ext_latin-100.woff2') format("woff2")
}

@font-face {
    font-display: swap;
    font-family: Poppins;
    font-style: normal;
    font-weight: 300;
    src: url('../../Assets/Fonts/poppins-v20-latin-ext_latin-300.woff2') format("woff2")
}

@font-face {
    font-display: swap;
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    src: url('../../Assets/Fonts/poppins-v20-latin-ext_latin-600.woff2') format("woff2")
}

@font-face {
    font-display: swap;
    font-family: Poppins Bold;
    font-style: normal;
    font-weight: 700;
    src: url('../../Assets/Fonts/poppins-v20-latin-ext_latin-700.woff2') format("woff2")
}
