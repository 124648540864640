$blue-color: #009ee0 !default;

:root {
    --poker-table__max-width: 1200px;
    --poker-table__background-color: $blue-color;
    --poker-table__voting-cards-background-color: #ffffff;
}

.poker-table {
    display: grid;
    flex: 1 0 auto;
    grid-template-columns: 1fr 1.5fr 1fr;
    grid-template-rows: 1fr .5fr 1fr;
    grid-auto-columns: 1fr;
    gap: 15px;
    grid-auto-flow: row;
    grid-template-areas:
    "space1 area1 space2"
    "area4 table area2"
    "space4 area3 space3";
    width: 100%;
    max-width: rem(var(--poker-table__max-width));
    margin-left: auto;
    margin-right: auto;

    @media (max-width: rem(992px)) {
        grid-template-columns: 1fr 1fr;
        grid-auto-flow: column;
        gap: 15px 0;
        grid-template-areas:
            "area1 area2"
            "table table"
            "area3 area4";
    }

    &__table {
        display: flex;
        justify-content: center;
        align-items: center;
        align-self: center;
        flex-direction: column;
        grid-area: table;
        min-width: rem(300px);
        min-height: rem(200px);
        border-radius: rem(40px);
        background-color: rgba($blue-color, .4);
        border: 1px solid rgba(0,0,0,0.05);
        box-shadow: rem(0 0 8px 0) rgba(0,0,0,0.2);

        @media (max-width: rem(992px)) {
            width: 100%;
            max-width: rem(600px);
            margin-left: auto;
            margin-right: auto;
        }
    }

    @for $i from 1 through 4 {
        &__space#{$i} {
            opacity: 0;
            visibility: hidden;
            display: none;
            grid-area: space#{$i};
        }

        &__area#{$i} {
            display: flex;
            justify-content: center;
            grid-area: area#{$i};
            padding: 10px;

            @if $i == 1 {
                align-items: flex-end;
            }
        }
    }
}

.poker {
    .ant-layout-content {
        padding-bottom: 150px;
    }

    .voting-cards-wrapper {
        display: flex;
        align-items: center;
        position: fixed;
        bottom: rem(42px);
        left: 0;
        right: 0;
        height: rem(130px);
        margin-top: rem(40px);
        border-top: rem(1px) solid var(--app-backgorund-color);
        background-color: var(--poker-table__voting-cards-background-color);
        overflow: auto;
    }

    .voting-cards {
        display: flex;
        align-items: center;
        margin-left: auto;
        margin-right: auto;

        &__card {
            flex: 0 0 auto;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            width: 60px;
            height: 80px;
            padding: 0;
            border: 2px solid #dfdfdf;
            border-radius: rem(6px);
            background-color: transparent;
            cursor: pointer;
            transform: translateY(0);
            font-size: rem(16px);
            font-weight: bold;
            transition: transform .6s, border .6s;

            &.voting-cards__card--active,
            &:hover {
                transform: translateY(rem(-5px));
                transition: transform .15s, border .6s;
            }

            &.voting-cards__card--active {
                transform: translateY(rem(-10px));
                border-color: #009EE0;
            }

            & + .voting-cards__card {
                margin-left: 10px;
            }
        }
    }
}
